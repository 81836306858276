<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <template v-if="!isCheckEmail">
                    <v-card elevation="2" class="my-6">
                        <v-app-bar color="#3F51B5" dark flat dense>
                            <v-tooltip bottom>
                                <template #activator="{ on, attrs }">
                                    <v-btn icon @click="$router.go(-1)" v-bind="attrs" v-on="on">
                                        <font-awesome-icon :icon="['fas', 'chevron-left']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template>
                                Back
                            </v-tooltip>
                            <v-app-bar-title>Add email address</v-app-bar-title>
                        </v-app-bar>
                    <template v-if="step === 'start' && !redirect">
                        <v-card-text>
                            <p>What email address do you want to add?</p>
                        </v-card-text>
                        <v-form @submit.prevent="submit" onSubmit="return false;" @keyup.enter.native.prevent="submit" class="mx-4 pb-6">
                            <v-text-field v-model="email" ref="emailInput" dense solo :color="primaryColor" hint="This will be your email. You can change it later." placeholder="Email" :error-messages="inputError">
                                <template #prepend-inner>
                                    <font-awesome-icon :icon="['fas', 'envelope']" fixed-width/>
                                </template>
                            </v-text-field>
                            <v-row no-gutters justify="center">
                            <v-btn @click="submit" elevation="4" :color="primaryColor" class="white--text mt-4">Continue</v-btn>
                            </v-row>
                        </v-form>
                    </template>
                    <template v-if="['start', 'verify_email'].includes(step) && redirect">
                        <v-card-text>
                            <p><a :href="redirect">Continue to email verification</a></p>
                        </v-card-text>
                    </template>
                    <template v-if="step === 'verify_email' && !isViewReady">
                        <v-card-text>
                            <p>Please wait...</p>
                        </v-card-text>
                    </template>
                    <template v-if="step === 'verify_email' && isViewReady && registered">
                        <v-text-field v-model="email" dense solo flat :color="primaryColor" placeholder="Email" readonly>
                            <template #prepend-inner>
                                <font-awesome-icon :icon="['fas', 'envelope']" fixed-width/> <!-- style="font-size: 20px;" -->
                            </template>
                        </v-text-field>
                        <v-card-text>
                            <!-- NOTE: since the user verified the email address, we can inform them of the status -->
                            <p>
                                This email address has already been registered.
                            </p>
                            <p><router-link :to="{ name: 'login', query: { email: email } }">Sign in to your account</router-link></p>
                            <p><a @click.prevent="switchAccount" href="#">Use a different email address</a></p>
                        </v-card-text>
                    </template>
                    <template v-if="step === 'verify_email' && isViewReady && !registered">
                        <v-card-text>
                            <p>Please wait...</p>
                        </v-card-text>
                    </template>
                    </v-card>
            </template>
            <template v-if="isCheckEmail">
                <v-card>
                    <v-toolbar dense flat :color="primaryColor" dark>
                        <v-toolbar-title>Check your inbox</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <p>Look for an email with the subject <b>[Cryptium ID] email verification</b>.</p>
                            <!-- TODO: we should show the subject line of the email , like "[brand] email verification" and the email address we're sending it from, to help users find it; this info should be available from the server since it just sent the message.  we can show it here in a table like we're showing an email, with "from", "subject", "to" and put the pencil icon next to the "to" value if the user wants to edit,  instead of a readonly form field.   we can even show a message body area with a label "look for this message in your inbox or spam folder". -->
                    </v-card-text>
                    <v-form @submit.prevent="switchAccount" onSubmit="return false;" @keyup.enter.native.prevent="switchAccount" class="mx-4 pb-6">
                        <v-text-field
                            :value="email"
                            label="Email"
                            :color="primaryColor"
                            disabled
                            readonly
                        >
                            <template #append-outer>
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn icon :color="primaryColor" @click="switchAccount()" v-on="on">
                                            <span :color="primaryColor">
                                                <font-awesome-icon :icon="['fas', 'pencil-alt']" size="1x"/>
                                            </span>
                                        </v-btn>
                                    </template>
                                    <span>Edit email address</span>
                                </v-tooltip>
                            </template>
                        </v-text-field>
                    </v-form>
                </v-card>
                <v-alert border="left" :color="primaryColor" colored-border class="mt-8"> <!-- previously color="blue darken-2"  -->
                    <!-- <p class="ma-0 pa-0">Check your inbox for a link to continue.</p> -->
                    <p class="mx-0 mb-0 pa-0 grey--text text--darken-1 font-weight-light">If you don't receive an email, check that the email you entered is correct. Also, some email providers mistake the verification email for spam, so if it&apos;s not in the inbox, please check the spam folder.</p>
                </v-alert>
            </template>
            <p class="mb-15"></p>
            <v-alert type="error" v-if="serverError">
                An error occurred while processing your request. Please try again or contact customer support.
            </v-alert>
            <v-alert type="error" v-if="requestError">
                We could not send a verification email. Please try again. If the problem continues, try with a different email address or contact customer support.
            </v-alert>
            <v-alert type="error" v-if="forbiddenError">
                The link is expired or invalid. Check that the email you entered is correct and try again.
            </v-alert>
                </v-col>
            </v-row>

            <!-- <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="4" class="pa-5">
                        <v-card-text class="text-h6 text-center pa-0 pb-3">Name</v-card-text>
                        <v-divider class="mx-5 mb-5"></v-divider>
                        <v-row justify="center" class="pb-3">
                            <v-col cols="12">
                                <div class="font-weight-light text-body-1 text-center mx-5">
                                    <p>We will use this name to address you on our website and when we communicate by email, telephone, or letters.</p>
                                    <p>For example, "Sparky" or "Doctor Who"</p>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="mx-5">
                            <v-text-field
                                outlined
                                v-model=editableDisplayName
                                color="blue"
                                label="Your nickname, first name, or title"
                                :rules="nameRules"
                            ></v-text-field>
                        </v-row>
                        <v-row justify="center">
                            <v-btn elevation="4" class="blue white--text" @click="editDisplayName" :disabled="!isIdentityNameComplete">
                                <font-awesome-icon icon="check" fixed-width/>
                                Save
                            </v-btn>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row> -->
            <!-- <p>If you change this address we will send a verification email and you must click on the link in the email to confirm the change.</p> -->
            <!-- <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="4" class="pa-5">
                        <v-card-text class="text-h6 text-center pa-0 pb-3">Email</v-card-text>
                        <v-divider class="mx-5 mb-5"></v-divider>
                        <v-row justify="center" class="pb-3">
                            <v-col cols="12">
                                <div class="font-weight-light text-body-1 text-center mx-5">
                                    <p>We will use this email address to send you electronic messages.</p>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="mx-5">
                            <v-text-field
                                outlined
                                v-model=editableEmail
                                color="blue"
                                label="Your email address"
                                :rules="emailRules"
                                readonly
                                disabled
                            ></v-text-field>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row> -->
            <!-- <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-overline mb-0">Other</p>
                    <p>
                        <router-link :to="{ path: '/user/delete', query: { acct: this.$route.query.acct } }">How to delete this profile</router-link>
                    </p>
                </v-col>
            </v-row> -->
        </v-col>
    </v-row>
</template>

<script>
import { toText } from '@libertyio/time-util-js';
import { mapState /* , mapGetters */ } from 'vuex';
import { isValidEmail } from '@/sdk/input';
import { INTENT_PROFILE } from '@/sdk/constants';

/**
 * NOTE: while this page has some similarities with SignupActivity.vue, they
 * are different because this page is for a registered & authenticated user
 * to add a new email address to their profile, whereas the signup is for a
 * non-registered and non-authenticated user to create a new profile, so we
 * have different prompts and steps.
 */

export default {
    data: () => ({
        isViewReady: false,
        steps: ['start', 'verify_email'],
        step: 'start',
        email: '',
        submitTimestamp: null,
        redirect: null,
        // older ones, check if we're using
        interactionId: null,
        duration: null,
        verificationExpires: null,
        verificationExpiresText: null,
        serverError: false,
        serverErrorTimeout: null,
        requestError: false,
        requestErrorTimeout: null,
        inputError: null,
        inputErrorTimeout: null,
        forbiddenError: null,
        forbiddenErrorTimeout: null,
        registered: false,
        // emailRules: [
        //     (v) => !v || isValidEmail(v) || 'Enter your email address',
        // ],
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        displayName() {
            if (typeof this.user.display_name === 'string' && this.user.display_name.trim().length > 0) {
                return this.user.display_name;
            }
            return '(unknown)';
        },
        email() {
            if (typeof this.user.email === 'string' && this.user.email.trim().length > 0) {
                return this.user.email;
            }
            return '(unknown)';
        },
        // TODO: should come from $brand but since we're in profile management, the $brand would be the Cryptium ID brandprofile
        primaryColor() {
            return 'blue darken-2';
        },
    },
    watch: {
    },
    methods: {
        init() {
            console.log('Preferences.vue: init');
        },
        resetErrors() {
            this.serverError = false;
            if (this.serverErrorTimeout) {
                clearTimeout(this.serverErrorTimeout);
                this.serverErrorTimeout = null;
            }
            this.requestError = false;
            if (this.requestErrorTimeout) {
                clearTimeout(this.requestErrorTimeout);
                this.requestErrorTimeout = null;
            }
            this.inputError = null;
            if (this.inputErrorTimeout) {
                clearTimeout(this.inputErrorTimeout);
                this.inputErrorTimeout = null;
            }
            this.forbiddenError = false;
            if (this.forbiddenErrorTimeout) {
                clearTimeout(this.forbiddenErrorTimeout);
                this.forbiddenErrorTimeout = null;
            }
            this.redirect = null;
            this.verificationExpires = null;
        },
        // TODO: move to global activate function (see cygnia web apps for example)
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async submit() {
            if (Number.isInteger(this.submitTimestamp) && this.submitTimestamp + 500 > Date.now()) {
                return;
            }
            this.submitTimestamp = Date.now();
            try {
                this.resetErrors();
                if (typeof this.email !== 'string' || this.email.trim().length === 0 || !isValidEmail(this.email)) {
                    this.inputError = 'Please enter an email address';
                    this.inputErrorTimeout = setTimeout(() => { this.inputError = null; }, 15000); // clear message in 15 seconds
                    return;
                }
                this.$store.commit('loading', { addEmail: true });
                const request = {
                    email: this.email,
                    intent: INTENT_PROFILE,
                };
                console.log(`request ${JSON.stringify(request)}`);
                const response = await this.$client.user(this.user.id).email.startVerifyEmail(request);
                console.log(`Signup.vue: response ${JSON.stringify(response)}`);
                if (response?.status) {
                    switch (response.status) {
                    case 'check_email':
                        if (Number.isInteger(response.duration)) {
                            this.duration = response.duration; // how long the email token is valid
                            this.verificationExpires = Date.now() + response.duration;
                            this.verificationExpiresText = toText(response.duration);
                        } else {
                            this.duration = null;
                            this.verificationExpires = null;
                            this.verificationExpiresText = null;
                        }
                        this.step = 'check_email';
                        break;
                    case 'redirect':
                        if (response.redirect) {
                            // show a link after 2 seconds in case auto-redirect fails
                            setTimeout(() => {
                                this.redirect = response.redirect;
                            }, 2000);
                            // use replace so that when user taps 'back' button from there, they won't
                            // end up being redirected again to where they just wanted to come back from
                            if (typeof window.location.replace === 'function') {
                                window.location.replace(response.redirect);
                            } else {
                                // TODO: also show link for user to click
                                window.location.href = response.redirect;
                            }
                            return;
                        }
                        console.error('signup error: server redirect response missing redirect url');
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                        break;
                    case 'display_name':
                        // prompt user to enter display name
                        this.step = 'display_name';
                        this.$router.replace({ name: 'signup', query: { ...this.$route.query, step: 'display_name', t: Date.now() } });
                        this.$nextTick(() => {
                            setTimeout(() => { this.activate('displayNameInput'); }, 1);
                        });
                        break;
                    case 'setup_loginshield':
                        // user record was created but LoginShield not set up yet
                        this.step = 'setup_loginshield';
                        break;
                    case 'authenticated':
                        await this.$store.dispatch('refresh');
                        // user is already authenticated with same email address, so complete account setup steps
                        this.step = 'notice';
                        this.$router.replace({ name: 'signup', query: { ...this.$route.query, step: 'notice', t: Date.now() } });
                        break;
                    case 'setup_required':
                        // user record was created, but authentication not set up yet
                        // TODO: check if user has loginshield set up yet; if not redirect to that setup; if already set up, check if user has an app linked; if not, tell user about the app and hsow download links
                        await this.$store.dispatch('refresh');
                        this.setup();
                        break;
                    case 'login_required':
                        // email is verified but user not authenticated here; redirect to login
                        this.$router.push({ name: 'login' });
                        this.registered = true;
                        this.step = 'verify_email';
                        break;
                    case 'error':
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                        break;
                    default:
                        console.error(`signup error: unexpected status from server: ${JSON.stringify(response.status)}`);
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    console.error('signup error: server response missing status');
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = null; }, 15000); // clear message in 15 seconds
                }
            } catch (err) {
                console.error('failed to sign up', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.resetErrors();
                        this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { addEmail: false });
                this.isViewReady = true;
            }
        },
    },
    mounted() {
    },
};
</script>
